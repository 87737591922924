import React, { Component } from "react";
import homedata from "../../data/home.json";

class FactsSection extends Component {
  render() {
    let factsInfo = homedata.funfacts;
    let facts = factsInfo.facts;
    let publicUrl = process.env.PUBLIC_URL;

    const inlineStyle = {
      backgroundImage: "url(" + publicUrl + factsInfo.bgImg + ")",
    };

    return (
      <section
        id="facts-section"
        ref="counter"
        className="pt100 pb100 bg-parallax"
        style={inlineStyle}
      >
        <div className="color-overlay opacity-8"></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center mb-4">
              <h2 style={{color:"white"}} className="section-title">Nuestros Clientes</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            {facts.map((element, i) => {
              return (
                <div key={i} className="col-md-1 col-sm-4">
                  <div className=" ">
                    <img
                      src={element.icon}
                      alt={element.title}
                      style={
                        {
                          // width: "50px",
                          // height: "50px",
                          // marginBottom: "10px",
                        }
                      }
                    />
                    <div className="counter-number">
                      <span className="counter">{element.number}</span>
                      {element.unit}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="bottom-pattern d-none d-lg-block">
          <img
            src={`${publicUrl}/assets/img/bg-pattern-light.png`}
            alt="ng pattren"
          />
        </div>
      </section>
    );
  }
}

export default FactsSection;
