import React, { Component } from 'react';
import homedata from '../../data/home.json';

class Features extends Component {
    render() {
        let factsInfo = homedata.features;
        let sectionHeadingInfo = factsInfo.sectionHeading;
        let featureContent = factsInfo.singleFeature;
        let publicUrl = process.env.PUBLIC_URL;

        const certificationSectionStyle = {
            width: '100%',
            padding: '20px',
            background: `#DEEEFC`,
            backgroundSize: 'cover',
            color: '#7B87A6',
            textAlign: 'center',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        };

        const certificationTextStyle = {
            padding: '20px',
            background: '#FFFF',
            borderRadius: '8px'
        };

        const ulStyle = {
            listStyleType: 'none',
            padding: 0
        };

        const liStyle = {
            marginBottom: '10px'
        };

        return (
            <section id="features" className="pt100 pb100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb60">
                                <h2 dangerouslySetInnerHTML={{ __html: sectionHeadingInfo.title }}></h2>
                                <hr className="lines" />
                                <p>{sectionHeadingInfo.desc}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {/* Cuadro de Certificación de Competencias */}
                        <div className="col-lg-6 col-md-12 mt-5">
                            <div className="certification-section" style={certificationSectionStyle}>
                                <div className="certification-content" style={certificationTextStyle}>
                                    <h2>¡No basta con saberlo…Demuestralo!</h2>
                                    <p>Capacítate con fines de certificación en los estándares:</p>
                                    <ul style={ulStyle}>
                                        <li style={liStyle}>EC0217.01 Impartición de cursos de formación de capital humano de manera presencial grupal.</li>
                                        <li style={liStyle}>EC0076 Evaluación de la competencia de candidatos con base en Estándares de Competencia.</li>
                                        <li style={liStyle}>EC0105 Atención al ciudadano en el sector público.</li>
                                        <li style={liStyle}>EC0553 Comunicación efectiva en el trabajo.</li>
                                        <li style={liStyle}>EC0554 Trabajo en equipo.</li>
                                        <li style={liStyle}>EC0391.01 Verificación de las condiciones de seguridad e higiene en los centros de trabajo.</li>
                                        <li style={liStyle}>EC0305 Prestación de servicios de atención a clientes.</li>
                                        <li style={liStyle}>EC0401 Liderazgo en el sector público.</li>
                                        <li style={liStyle}>Entre otros.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 mt-5">
                            <div className="certification-section" style={certificationSectionStyle}>
                                <div className="certification-content" style={certificationTextStyle}>
                                    <h2>¡Contáctanos para desarrollar un plan de capacitación único y efectivo para tu negocio!</h2>
                                    <p>Capacitación especializada:</p>
                                    <ul style={ulStyle}>
                                        <li  style={liStyle}>Ofrecemos capacitación personalizada diseñada a la medida de cada empresa, asegurando que nuestros programas se alineen perfectamente con las necesidades y objetivos específicos de tu organización. Además, incluimos cursos especializados para la administración pública, garantizando soluciones efectivas para todos los sectores.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Cuadro de Feature Content */}
                        <div className="col-md-12 mt-4">
                                    <div className="row">
                                        <div className="col-md-6">
                                            {featureContent.featurePart1.map((element, i) => (
                                                <div key={i} className="media single-feature-item">
                                                    <div className="feature-icon">
                                                        <i className={element.icon}></i>
                                                    </div>
                                                    <div className="media-body">
                                                        <h5>{element.title}</h5>
                                                        <p>{element.desc}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-md-6">
                                            {featureContent.featurePart2.map((element, i) => (
                                                <div key={i} className="media single-feature-item">
                                                    <div className="feature-icon">
                                                        <i className={element.icon}></i>
                                                    </div>
                                                    <div className="media-body">
                                                        <h5>{element.title}</h5>
                                                        <p>{element.desc}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Features;
